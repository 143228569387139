import { NextPage } from 'next'

import { Grid, Typography } from '@mui/material'
import React from 'react'

const NotFound: NextPage = () => {
  return (
    <Grid container justifyContent="center" p={10} pt={20}>
      <Typography variant="subtitle1" color="textSecondary">
        {'指定したページは存在しません'}
      </Typography>
    </Grid>
  )
}

export default React.memo(NotFound)
